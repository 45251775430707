import React from 'react'

const TermsConditions = () => {
  return (
    <div className="subpage-container">
      <h1>Terms Conditions</h1>
    </div>
  )
}

export default TermsConditions
